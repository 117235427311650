.about-section {
  background: linear-gradient(135deg, #8b8b88, #161314);
  padding: 2rem;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 2rem auto; /* Margin auto to center horizontally */
  max-width: 800px;
  /* Remove vertical centering properties */
  margin-top: 1rem; /* Add margin to avoid navbar overlap */
  margin-bottom: 4rem; /* Add margin to avoid footer overlap */
}

.about-heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Arial', sans-serif;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
}

.profile {
  max-width: 300px;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.profile:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.bio {
  max-width: 600px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: justify;
  background: rgba(255, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease-in-out;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .about-heading {
    font-size: 2rem;
  }

  .profile {
    max-width: 200px;
  }

  .bio {
    max-width: 90%;
    font-size: 1rem;
    line-height: 1.2;
  }
}

/* Animation for the bio section */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
