.navigation {
    background-color: #333;
    padding: 1rem;
  }
  
  .navigation ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 0;
  }
  
  .navigation li {
    margin: 0;
  }
  
  .navigation a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    position: relative;
  }
  
  /* Active link styles */
  .navigation a.active {
    font-weight: bold;
  }
  
  /* Add a hover effect */
  .navigation a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #f5f2f1;
    transition: width 0.3s;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
  .navigation a:hover::after {
    width: 100%;
  }
  
  /* Add some animations */
  @keyframes slideIn {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .navigation li {
    animation: slideIn 0.5s ease-in-out;
  }