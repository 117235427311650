
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header styles */
header {
  background-color: #333;
  color: white;
  padding: 1rem;
}

header h1 {
  margin: 0;
}


/* Main content styles */
main {
  padding: 2rem;
}

/* Section styles */
section {
  display: block; /* Ensure sections are displayed for debugging */
}

/* Profile photo styles */
.profile-photo {
  max-width: 150px;
  border-radius: 50%;
}

/* Form error message styles */
.error-message {
  color: red;
  display: none;
}

/* Footer styles */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  bottom: 0;
}
