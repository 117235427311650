.contact-section {
    padding: 2rem;
    text-align: center;
    background: linear-gradient(135deg, #8b8a8a, #ffffff);
    color: #333;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-heading {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .contact-form {
    max-width: 500px;
    margin: 0 auto;
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .input-error {
    border-color: #ff6b6b;
  }
  
  .error-message {
    color: #ff6b6b;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: block;
  }
  
  .submit-button {
    background: #007bff;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .submit-button:hover {
    background: #0056b3;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .contact-section,
  .contact-form,
  .contact-heading,
  .form-group,
  .submit-button {
    animation: fadeIn 1s ease-in-out;
  }