.resume-section {
    padding: 2rem;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 800px;
  }
  
  .resume-heading {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .resume-content {
    text-align: left;
    padding: 0 2rem;
  }
  
  .download-link {
    display: inline-block;
    margin-bottom: 2rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .download-link:hover {
    background-color: #0056b3;
  }
  
  .proficiencies-list {
    list-style-type: none;
    padding: 0;
  }
  
  .proficiencies-list li {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    padding: 0.5rem;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .proficiencies-list li:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }