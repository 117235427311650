.footer {
    background-color: #333;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
    border-top: 3px solid #f1f2f3;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-icons {
    margin-top: 0.5rem;
  }
  
  .social-icon {
    width: 30px;
    height: 30px;
    margin: 0 0.5rem;
    transition: transform 0.3s;
  }
  
  .social-icon:hover {
    transform: scale(1.2);
  }
  
  .footer p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
  }